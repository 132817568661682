import {useState} from "react";

import {TextField, MenuItem, Box} from "@mui/material";

import {countries} from "./constants";

const CountrySelect = ({register, errors}) => {
    const [selectedCountry, setSelectedCountry] = useState("");

    const handleCountryChange = (event) => {
        const value = event.target.value;
        setSelectedCountry(value); // Update the local state
    };

    return (
        <Box>
            {/* Country Select */}
            <TextField
                select
                label="Country"
                variant="outlined"
                value={selectedCountry}
                onChange={(e) => {
                    handleCountryChange(e); // Update the state
                }}
                {...register("country", {
                    required: "Country is required",
                    onChange: (e) => handleCountryChange(e), // Ensure this works with register
                })}
                error={!!errors.country}
                helperText={errors.country?.message}
                fullWidth
            >
                {countries.map((country) => (
                    <MenuItem key={country} value={country}>
                        {country}
                    </MenuItem>
                ))}
            </TextField>

            {/* Other Country Input */}
            {selectedCountry === "Other" && (
                <TextField
                    label="Specify Country"
                    variant="outlined"
                    {...register("otherCountry", {required: "Please specify the country"})}
                    error={!!errors.otherCountry}
                    helperText={errors.otherCountry?.message}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
            )}
        </Box>
    );
};

export default CountrySelect;
