import {useEffect, useRef} from "react";

import {Alert, Button, Card, CircularProgress, Container, TextField, Typography} from "@mui/material";
import SendIcon from '@mui/icons-material/Send';
import {Box} from "@mui/system";

import {ChatMessage} from "../types";

const Chat = ({openChat, chatMessages, chatMessage, setChatMessage, handleChatReport, isLoading}: {
    openChat: boolean,
    chatMessages: ChatMessage[],
    chatMessage: string,
    setChatMessage: (message: string) => void;
    handleChatReport: () => void;
    isLoading: boolean
}) => {
    const chatContainerRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        // Scroll to the bottom of the chat container when chatMessages or isLoading changes
        if (chatContainerRef.current) {
            chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
        }
    }, [chatMessages, isLoading]);

    return (
        <Card sx={{
            maxWidth: openChat ? "100%" : 0,
            overflow: openChat ? "auto" : "hidden",
            padding: openChat ? "16px" : 0,
            display: openChat ? "block" : "none",
            borderRadius: 1,
            marginX: 2
        }}>

            <Typography variant='h6' gutterBottom>
                Scout chat AI 🤖
            </Typography>
            <Alert severity='warning'>This is a first version, so it may contain errors. Therefore, please
                consider this information as a guidance tool.
            </Alert>

            {/*chat messages*/}
            <div
                ref={chatContainerRef}
                style={{
                    maxHeight: "300px", // Limit the height for scrolling
                    overflowY: "auto", // Add vertical scroll if content overflows
                    wordWrap: "break-word", // Allow long words to break
                    overflowWrap: "break-word", // Ensure compatibility
                    whiteSpace: "pre-wrap", // Preserve line breaks in the text
                    padding: "8px",
                    borderRadius: "4px",
                    marginTop: '15px'
                }}>

                {chatMessages.map((item, index) => (
                    <Box
                        style={{
                            backgroundColor: item.isFromUser ? "rgba(87, 113, 222, 0.2)" : 'rgba(87, 222, 133, 0.2)',
                            padding: 8,
                            borderRadius: '5px',
                            marginBottom: 10
                        }}>
                        <Typography variant="body2" component="div" sx={{
                            wordWrap: "break-word", // Ensure long words wrap
                            overflowWrap: "break-word", // Better browser support
                            whiteSpace: "pre-wrap", // Preserve formatting
                        }}>
                            {item.message}
                        </Typography>
                    </Box>
                ))
                }
                {isLoading && <CircularProgress/>}
            </div>

            <Box sx={{display: 'flex', flexDirection: 'row'}}>
                {/* text input */}
                <TextField
                    label="Enter a question"
                    variant="outlined"
                    value={chatMessage}
                    onChange={(e) => {
                        setChatMessage(e.target.value)
                    }}
                    fullWidth
                    multiline // Enables the multiline feature
                    rows={2} // Sets the number of visible rows
                />
                <Button onClick={() => handleChatReport()}>
                    <SendIcon/>
                </Button>
            </Box>
        </Card>
    )
}

export default Chat;