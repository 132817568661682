import React from 'react';
import {TextField, MenuItem, Box} from '@mui/material';

const PropertyConditionSelect = ({register, errors}) => {
    const conditionOptions = [
        {value: 'new', label: 'New'},
        {value: 'renovated', label: 'Renovated'},
        {value: 'well-maintained', label: 'Well Maintained'},
        {value: 'needs-repairs', label: 'Needs Repairs'},
        {value: 'fixer-upper', label: 'Fixer-Upper'},
        {value: 'as-is', label: 'As-Is'},
        {value: 'needs-tlc', label: 'Needs TLC'},
        {value: 'dilapidated', label: 'Dilapidated'}
    ];

    return (
        <Box>
            <TextField
                select
                label="Property Condition"
                variant="outlined"
                fullWidth
                {...register('condition', {required: 'It is required'})}
                error={!!errors.condition}
                helperText={errors.condition?.message}
            >
                {conditionOptions.map((condition) => (
                    <MenuItem key={condition.value} value={condition.value}>
                        {condition.label}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default PropertyConditionSelect;
