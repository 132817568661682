import BaseSearchedProperties from "./components/Base";
import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";
import {useGetUserSearchedProperties} from "services/propertiesSearched";

const UserSearchedProperties = ({limit, title, displayButtons = false}: {
    limit?: number,
    title?: string,
    displayButtons: boolean
}) => {

    const {user} = useAuth();

    const {landlord} = useGetLandlordProfile({userAuthId: user?.id})

    const {properties, isLoading} = useGetUserSearchedProperties({
            landlordId: landlord?.id
        })
    ;

    const propertiesToDisplay = (limit !== undefined && properties) ? properties.slice(0, limit) : properties;

    return <BaseSearchedProperties properties={propertiesToDisplay} isLoading={isLoading}
                                   displayButtons={displayButtons}/>
}

export default UserSearchedProperties;