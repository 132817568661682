import {Alert, Card, CardContent, Grid, Typography} from "@mui/material";
import {Box} from "@mui/system";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import SellOutlinedIcon from "@mui/icons-material/SellOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import BeachAccessOutlinedIcon from "@mui/icons-material/BeachAccessOutlined";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
import InsertChartOutlinedIcon from "@mui/icons-material/InsertChartOutlined";
import CardTravelIcon from "@mui/icons-material/CardTravel";
import InsightsIcon from "@mui/icons-material/Insights";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import {PropertySearched} from "types/entities/propertiesSearched";

import Rating from "components/rating";

import {extractEurValue, extractYield} from "../utils";

const BaseReport = ({property}: { property: PropertySearched }) => {
    const renderItem = (title: string, content: string, rating: number | undefined, Icon: any) => {

        return (
            <Card style={{marginBottom: 50, padding: 15}}>
                <CardContent>
                    <Box style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between', // Spread the title and rating across the row
                        marginBottom: 10
                    }}>
                        <Box style={{display: 'flex', alignItems: 'center'}}>
                            <Icon style={{marginRight: 8}}/>
                            <Typography variant='h3' sx={{whiteSpace: 'normal'}}>
                                {title}
                            </Typography>
                        </Box>

                        {/* Box to display the rating */}
                        {rating &&
                            <Rating rating={rating}/>
                        }
                    </Box>
                    <Typography style={{
                        wordWrap: 'break-word',  // Ensures long words wrap to the next line
                        whiteSpace: 'pre-wrap',  // Preserves the new lines, tabs, etc.
                    }}>
                        {content}
                    </Typography>
                </CardContent>
            </Card>
        )
    };

    const itemIcons = {
        'Summary': InfoOutlinedIcon,
        'Price analysis': SellOutlinedIcon,
        'Location': LocationOnOutlinedIcon,
        'Rental income potential': MapsHomeWorkOutlinedIcon,
        // TODO: change icon
        'Short-term rental income potential': BeachAccessOutlinedIcon,
        'Price increase potential': TrendingUpOutlinedIcon,
        'Operating costs': InsertChartOutlinedIcon,
        // TODO: change icon
        'Additional costs breakdown': CardTravelIcon,
        'ROI': InsightsIcon
    };

    return (
        <>
            {/* property info */}
            <Card style={{marginBottom: 30, padding: 15, paddingBottom: 0}}>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12} sm={3}>
                            <Box
                                style={{
                                    width: '100%',
                                    height: 180,
                                    paddingRight: '20px'
                                }}
                            >
                                <img
                                    src={property.propertyInfo.image || 'https://www.rootinc.com/wp-content/uploads/2022/11/placeholder-1-768x512.png'}
                                    alt={property.propertyInfo.title || 'Property Image'}
                                    style={{
                                        width: '100%',
                                        height: '100%',
                                        borderRadius: 10,
                                        objectFit: 'cover',
                                    }}
                                />
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={9}>
                            {/*title*/}
                            <Typography variant='h2' sx={{marginBottom: 2}}>
                                {property.propertyInfo.title}
                            </Typography>

                            {/* general info property */}
                            <Box style={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}>
                                {/*<Typography>*/}
                                {/*    <b>Property:</b> {property.propertyInfo.title}*/}
                                {/*</Typography>*/}
                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 20
                                }}>
                                    <LocationOnOutlinedIcon style={{marginRight: 5}}/>
                                    <Typography>
                                        {property.propertyInfo.location.replace("Ubicación", "").replace(/\n+/g, ', ').replace(/^, /, '').trim()}
                                    </Typography>
                                </Box>
                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 17
                                }}>
                                    <SellOutlinedIcon style={{marginRight: 5}}/>
                                    <Typography>
                                        {property.propertyInfo.price}
                                    </Typography>
                                </Box>

                                <Box style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    marginBottom: 15
                                }}>
                                    <HomeOutlinedIcon style={{marginRight: 5}}/>
                                    <Typography style={{
                                        wordWrap: 'break-word',  // Ensures long words wrap to the next line
                                        whiteSpace: 'pre-wrap',  // Preserves the new lines, tabs, etc.
                                    }}>
                                        {property.propertyInfo.infoFeatures.replace(/\s+/g, ' ').replace(/(\d+ m²)/, '$1,').trim()}
                                    </Typography>
                                </Box>

                            </Box>
                        </Grid>
                    </Grid>
                </CardContent>
            </Card>

            {/**user message*/}
            <Alert
                // icon={<CheckIcon fontSize="inherit"/>}
                severity="success"
                style={{marginBottom: 30}}
            >
                Notice: The information and analysis provided on Nidoscout.com is for informational purposes only
                and
                does not constitute financial, investment, or real estate advice. Any decisions you make based on
                the
                information provided by Nidoscout.com are done at your own risk.
            </Alert>

            {/* general stats */
            }
            <Grid
                container
                rowSpacing={'20px'}
                sx={{marginBottom: 3}}
            >
                {[
                    {
                        label: 'Potential rental income',
                        value: `${(extractEurValue(property.report.items.find((item) => item.item === 'Rental income potential').value) || 'N/A')} € / month`
                    },
                    {
                        label: 'Gross annual yield',
                        value: `${(extractYield(property.report.items.find((item) => item.item === 'Rental income potential').value) || 'N/A')} %`
                    },
                    {
                        label: 'Overall rating', value: property.report.overallRating.toFixed(1)
                    },
                    {
                        label: 'Location rating',
                        value: property.report.items.find((item) => item.item === 'Location').rating
                    },
                ].map((item) =>
                    <Grid item xs={12} sm={3}>
                        <Card sx={{width: '220px'}}>
                            <CardContent>
                                <Typography gutterBottom>
                                    {item.label}
                                </Typography>
                                <Typography variant='h4' sx={{fontSize: '23px'}}>
                                    {item.value}
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                )}
            </Grid>

            {/* items */
            }
            <pre>

                {/* overall report*/}
                <>{renderItem('Summary', property.report.overall, property.report.overallRating, itemIcons['Summary'])}</>

                {/*each item of the report*/}
                {property.report &&
                    property.report.items.map((item) => {
                        return <div
                            key={item.item}>{renderItem(item.item, item.value, item.rating, itemIcons[item.item])}</div>
                    })
                }</pre>
        </>
    )
}

export default BaseReport;