import React from 'react';
import {TextField, MenuItem, Box} from '@mui/material';

const PropertyTypeSelect = ({register, errors}) => {
    const propertyTypes = [
        {value: 'private-property', label: 'Private Property'},
        {value: 'apartment', label: 'Apartment'},
        {value: 'house', label: 'House'},
        {value: 'condo', label: 'Condo'},
        {value: 'townhouse', label: 'Townhouse'},
        {value: 'duplex', label: 'Duplex'},
        {value: 'studio', label: 'Studio'},
        {value: 'penthouse', label: 'Penthouse'},
        {value: 'loft', label: 'Loft'},
        {value: 'villa', label: 'Villa'},
        {value: 'cottage', label: 'Cottage'},
        {value: 'farm', label: 'Farm'},
        {value: 'land', label: 'Land'}
    ];

    return (
        <Box>
            <TextField
                select
                label="Property Type"
                variant="outlined"
                fullWidth
                {...register('propertyType', {required: 'Property type is required'})}
                error={!!errors.propertyType}
                helperText={errors.propertyType?.message}
            >
                {propertyTypes.map((property) => (
                    <MenuItem key={property.value} value={property.value}>
                        {property.label}
                    </MenuItem>
                ))}
            </TextField>
        </Box>
    );
};

export default PropertyTypeSelect;
