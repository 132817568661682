import {useState} from 'react';

import {TextField, MenuItem, Box} from '@mui/material';

import {currencies} from "../constants";

const CurrencySelect = ({register, errors}) => {
    const [selectedCurrency, setSelectedCurrency] = useState("");

    const handleCurrencyChange = (event) => {
        const value = event.target.value;
        setSelectedCurrency(value); // Update the local state
    };

    return (
        <Box>
            {/* Currency Select */}
            <TextField
                select
                label="Currency"
                variant="outlined"
                value={selectedCurrency}
                onChange={(e) => {
                    handleCurrencyChange(e); // Update the state
                }}
                {...register("currency", {
                    required: "Currency is required",
                    onChange: (e) => handleCurrencyChange(e), // Ensure this works with register
                })}
                error={!!errors.currency}
                helperText={errors.currency?.message}
                fullWidth
            >
                {[...currencies, {code: "Other", name: "Other"},].map((currency) => (
                    <MenuItem key={currency.code} value={currency.code}>
                        {currency.name}
                    </MenuItem>
                ))}
            </TextField>

            {/* Other Currency Input */}
            {selectedCurrency === "Other" && (
                <TextField
                    label="Specify Currency"
                    variant="outlined"
                    {...register("otherCurrency", {required: "Please specify the currency"})}
                    error={!!errors.otherCurrency}
                    helperText={errors.otherCurrency?.message}
                    fullWidth
                    style={{marginTop: "1rem"}}
                />
            )}
        </Box>
    );
};

export default CurrencySelect;
