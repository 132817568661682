import {TextField, Box} from '@mui/material';

import CountrySelect from "./countrySelector";

const LocationInput = ({register, errors}) => {
    return (
        <Box>
            {/* Country Input */}
            <Box style={{marginBottom: '1rem'}}>
                <CountrySelect errors={errors} register={register}/>
            </Box>

            {/* State Input */}
            <TextField
                label="State/Province/Region"
                variant="outlined"
                {...register('state', {required: 'State/Province/Region is required'})}
                error={!!errors.state}
                helperText={errors.state?.message}
                fullWidth
                style={{marginBottom: '1rem'}}
            />

            {/* City Input */}
            <TextField
                label="City"
                variant="outlined"
                {...register('city', {required: 'City is required'})}
                error={!!errors.city}
                helperText={errors.city?.message}
                fullWidth
                style={{marginBottom: '1rem'}}
            />

            {/* Street Address Input */}
            <TextField
                label="Street Address"
                variant="outlined"
                {...register('streetAddress', {required: 'Street address is required'})}
                error={!!errors.streetAddress}
                helperText={errors.streetAddress?.message}
                fullWidth
            />
        </Box>
    );
};

export default LocationInput;
