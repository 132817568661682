import {useNavigate} from "react-router-dom";

import {routes} from "config/routes";

import {PropertySearched} from "types/entities/propertiesSearched";
import Loader from "components/Loader";
import {Button, ButtonGroup, Container, Grid, Typography} from "@mui/material";
import ReportCard from "./ReportCard";
import {Box} from "@mui/system";

const BaseSearchedProperties = (
    {
        properties,
        isLoading,
        title,
        displayButtons = false
    }: {
        properties: PropertySearched[],
        isLoading: boolean,
        title?: string,
        displayButtons?: boolean
    }
) => {
    const navigate = useNavigate();

    const handleClick = (propertyId: number) => navigate(`/${routes.reports.base}/${routes.reports.property}/${propertyId}`);

    if (isLoading) {
        return <>
            <Loader/>
        </>
    }

    const handleGoToManualReport = () => {
        navigate(`/${routes.reports.base}/${routes.reports.manualReportCreation}`);
    };

    const handleGoToAutomaticReport = () => {
        navigate(`/`);
    };

    return <Container>
        {displayButtons && <Box
            sx={{
                display: 'flex',
                justifyContent: 'flex-end'
            }}
        >
            <ButtonGroup>
                <Button variant='outlined' onClick={handleGoToManualReport}>
                    Create manual report
                </Button>
                <Button variant='outlined' onClick={handleGoToAutomaticReport}>
                    Create automatic report
                </Button>
            </ButtonGroup>
        </Box>}
        {properties && properties.length > 0 ?
            <>
                <div style={{marginBottom: 35}}>
                    <Typography variant='h3'>
                        {title ? title : "Your searches"}
                    </Typography>
                    {/*<Typography>*/}
                    {/*    Here you can check all the properties you have searched for*/}
                    {/*</Typography>*/}
                </div>
                {/*<BaseSearchedProperties properties={propertiesToDisplay} isLoading={isLoading}/>*/}
                <Grid container spacing={2}>

                    {
                        properties && properties.map((property) => {
                            return (
                                <ReportCard property={property} handleClick={handleClick}/>
                            )
                        })
                    }
                </Grid>
            </>
            :
            <>
                <Typography variant={'h4'} gutterBottom>
                    No properties searched yet
                </Typography>
                <Typography>Start searching for any Idealista property to get a full report</Typography>
            </>
        }

    </Container>
}

export default BaseSearchedProperties;