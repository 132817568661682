import React, {useState} from 'react';
import {useForm, SubmitHandler} from 'react-hook-form';
import {useNavigate} from "react-router-dom";
import {supabase} from "config/supabaseClient";

import {
    TextField,
    Box,
    Container,
    Typography,
    Grid,
} from '@mui/material';
import {LoadingButton} from "@mui/lab";
import Divider from '@mui/material/Divider';

import {useAuth} from "hooks/auth";
import {useGetLandlordProfile} from "services/landlord/landlord";

import {PropertySearched} from "types/entities/propertiesSearched";
import {FormInputs} from "./types";

import PriceInput from "./components/PriceInput";
import CurrencySelect from "./components/CurrencySelect";
import LocationInput from "./components/Location";
import PropertyConditionSelect from "./components/PropertyConditionSelect";
import PropertyTypeSelect from "./components/PropertyTypeSelect";

import {routes} from "config/routes";

const ManualReportCreation = () => {
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const {user} = useAuth();

    const {landlord, isLoading: isLoadingLandlord} = useGetLandlordProfile({userAuthId: user.id})

    const {
        register,
        handleSubmit,
        formState: {errors, isValid},
    } = useForm<FormInputs>();

    const onSubmit: SubmitHandler<FormInputs> = async (form) => {
        setIsLoading(true);

        let property = {};

        // 1. Create the propertySearched in DB
        const {data: propertySearchedCreated, error} = await supabase
            .from('PropertySearched')
            .insert([
                // TODO: change this
                {link: 'deleteThis'},
            ])
            .select()

        property = propertySearchedCreated[0];

        // 2. Create relation property - user in DB
        const {} = await supabase
            .from('User-PropertySearched')
            .insert([
                {propertySearched: property['id'], user: landlord.id},
            ])
            .select()

        const propertyInfo: PropertySearched['propertyInfo'] = {
            title: form.title,
            infoFeatures: `${form.size} square meters, ${form.rooms} rooms, ${form.bathrooms} bathrooms`,
            image: '',
            price: `${form.price} ${!!form.currency ? form.currency : form.otherCurrency}`,
            comment: form.additionalInformation || '',
            details: `${form.condition}, It is ${form.propertyType}`,
            location: `${form.streetAddress}, ${form.city}, ${form.state}, ${form.country}`,
        };

        // 4. Create report
        const response = await fetch(
            "https://ajzrselqnmiqsgynwkuf.supabase.co/functions/v1/create-property-report",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({propertyInfo: propertyInfo}), // Send the link as the payload
            }
        );

        const data = await response.json();

        const report = {items: data.response.report, overall: data.response.overallReport};

        const getOverallRating = (report: any) => {
            const validItems = report.items.filter(item => item.rating !== undefined || item.rating !== null);
            const sum = validItems.reduce((acc, item) => acc + item.rating, 0);

            return validItems.length > 0 ? sum / validItems.length : 0;
        };

        // get overall rating
        // update overall rating
        report['overallRating'] = getOverallRating(report);

        // 4. save report in property record
        const {} = await supabase
            .from('PropertySearched')
            .update({
                report: report,
                propertyInfo: propertyInfo
            })
            .eq('id', property['id'])
            .select()

        // 5. return or redirect to page with param reportId as url param
        navigate(`/${routes.reports.base}/${routes.reports.property}/${property['id']}`)
    };

    return (
        <Container>
            <Typography variant='h3'>
                Create property report manually
            </Typography>

            <Box sx={{
                marginX: 2,
                marginTop: 3,
                marginBottom: 1,
            }}>
                <TextField
                    label="Title"
                    variant="outlined"
                    {...register('title', {required: 'It is required'})}
                    error={!!errors.title}
                    // helperText={errors.title?.message}
                    helperText='This is a general title you can use to identify this property'
                    fullWidth
                />
            </Box>

            <Grid container>
                <Grid item xs={12} sm={6}
                      sx={{
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2
                      }}>

                    <Typography variant='h5'>
                        General information
                    </Typography>

                    <PriceInput errors={errors} register={register}/>

                    <CurrencySelect errors={errors} register={register}/>

                    <TextField
                        label="Square meters"
                        variant="outlined"
                        {...register('size', {required: 'It is required'})}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        fullWidth
                        type='number'
                    />

                    <Divider/>

                    <Typography variant='h5'>
                        Location
                    </Typography>

                    <LocationInput register={register} errors={errors}/>
                </Grid>
                <Grid item xs={12} sm={6}
                      sx={{
                          padding: 2,
                          display: 'flex',
                          flexDirection: 'column',
                          gap: 2
                      }}
                >

                    <Typography variant='h5'>
                        Property details
                    </Typography>

                    <TextField
                        label="Number of rooms"
                        variant="outlined"
                        {...register('rooms', {required: 'It is required'})}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        fullWidth
                        type='number'
                    />

                    <TextField
                        label="Number of bathrooms"
                        variant="outlined"
                        {...register('bathrooms', {required: 'It is required'})}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        fullWidth
                        type='number'
                    />

                    <PropertyConditionSelect errors={errors} register={register}/>

                    <PropertyTypeSelect register={register} errors={errors}/>

                    <Divider/>
                    <Typography variant='h5'>
                        Additional information (Optional)
                    </Typography>

                    <TextField
                        label="Any additional information you consider it can affect the analysis"
                        variant="outlined"
                        {...register('additionalInformation', {
                            required: false
                        })}
                        error={!!errors.title}
                        helperText={errors.title?.message}
                        fullWidth
                        multiline
                        minRows={3}
                    />
                </Grid>

            </Grid>
            <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                padding: 2
            }}>
                <LoadingButton
                    loading={isLoading}
                    type="submit"
                    variant="contained"
                    color="primary"
                    disabled={isLoading || !isValid}
                    onClick={handleSubmit(onSubmit)}
                >
                    Create report
                </LoadingButton>
            </Box>
            {/*</Box>*/}
        </Container>
    );
};

export default ManualReportCreation;
