export const currencies = [
    // North America
    {code: "USD", name: "United States Dollar"},
    {code: "CAD", name: "Canadian Dollar"},
    {code: "MXN", name: "Mexican Peso"},

    // South America (Latin America)
    {code: "BRL", name: "Brazilian Real"},
    {code: "ARS", name: "Argentine Peso"},
    {code: "CLP", name: "Chilean Peso"},
    {code: "COP", name: "Colombian Peso"},
    {code: "PEN", name: "Peruvian Sol"},
    {code: "UYU", name: "Uruguayan Peso"},

    // Europe
    {code: "EUR", name: "Euro"},
    {code: "GBP", name: "British Pound"},
    {code: "CHF", name: "Swiss Franc"},
    {code: "RUB", name: "Russian Ruble"},

    // Asia
    {code: "JPY", name: "Japanese Yen"},
    {code: "CNY", name: "Chinese Yuan"},
    {code: "INR", name: "Indian Rupee"},
    {code: "KRW", name: "South Korean Won"},
    {code: "IDR", name: "Indonesian Rupiah"},
    {code: "MYR", name: "Malaysian Ringgit"},
    {code: "PHP", name: "Philippine Peso"},
    {code: "SGD", name: "Singapore Dollar"},
    {code: "THB", name: "Thai Baht"},
    {code: "VND", name: "Vietnamese Dong"},

    // Oceania
    {code: "AUD", name: "Australian Dollar"},
    {code: "NZD", name: "New Zealand Dollar"},

    // Middle East
    {code: "SAR", name: "Saudi Riyal"},
    {code: "AED", name: "United Arab Emirates Dirham"},
    {code: "TRY", name: "Turkish Lira"},

    // Africa
    {code: "ZAR", name: "South African Rand"},
    {code: "NGN", name: "Nigerian Naira"},
    {code: "EGP", name: "Egyptian Pound"},

];
