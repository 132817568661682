import {Box, Button, Card, CardContent, CardMedia, Grid, Typography} from "@mui/material";

import Rating from "components/rating";

import {PropertySearched} from "types/entities/propertiesSearched";

const ReportCard = ({property, handleClick}: {
    property: PropertySearched,
    handleClick: (propertyId: number) => void
}) => {
    return (
        <Grid item xs={12} sm={4} key={property.id}>
            <Card sx={{minWidth: 275}}>
                <CardMedia
                    sx={{height: 140}}
                    image={property.propertyInfo.image || 'https://www.rootinc.com/wp-content/uploads/2022/11/placeholder-1-768x512.png'}
                    title="green iguana"
                />
                <CardContent>
                    <Box style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography variant="h5" component="div"
                                    style={{
                                        marginTop: 10,
                                        marginBottom: 30,
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',      /* Prevents text from wrapping to a new line */
                                        overflow: 'hidden',
                                        maxWidth: '220px',
                                    }}>
                            {property.propertyInfo.title}
                        </Typography>
                        <Rating rating={property['report']['overallRating']}/>
                    </Box>
                    <Box style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }}>
                        <Typography style={{
                            fontWeight: 'bold',
                            color: '#646cec'
                        }}>{property.propertyInfo.price}</Typography>
                        <Button size="small" onClick={() => handleClick(property.id)}
                                style={{textDecoration: 'underline'}}>View report</Button>
                    </Box>
                </CardContent>
            </Card>
        </Grid>
    )
}

export default ReportCard;