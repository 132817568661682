import {useQuery} from "react-query";

import {PropertySearched} from "../../types/entities/propertiesSearched";

import {supabase} from "../../config/supabaseClient";

const resource = 'property-searched';

/**
 * Hook to get all the contracts from a property.
 */
export const useGetAllSearchedProperties = () => {
    const {data, isLoading} = useQuery<PropertySearched[], Error>(
        [resource],
        async () => {

            let properties: PropertySearched[] = [];

            // get user-properties
            let {data, error} = await supabase
                .from('PropertySearched')
                .select(`*`)
                .order('id', {ascending: true})
            ;

            properties = data as PropertySearched[];

            return properties

        }
    );

    return {properties: data, isLoading};
};

export const useGetUserSearchedProperties = ({
                                                 landlordId,
                                             }: {
    landlordId: string | undefined;
}) => {
    const {data: properties, isLoading} = useQuery<PropertySearched[], Error>(
        [resource],
        async () => {

            let properties: PropertySearched[] = [];

            // get user-properties
            let {data, error} = await supabase
                .from('User-PropertySearched')
                .select(`
                    *,
                    propertySearched(*)
                    `)

                // Filters
                .eq('user', landlordId)
                .order('id', {ascending: false})
            // format properties
            properties = data.map((item) => {
                return {...item['propertySearched'], isFavorite: item['isFavorite']}
            });

            return properties

        },
        {
            enabled: landlordId !== undefined
        }
    );

    return {properties, isLoading};
};