import {useState} from "react";

import {TextField} from "@mui/material";

// TODO: fix the value formatting
const PriceInput = ({register, errors}) => {
    const [rawValue, setRawValue] = useState(""); // Tracks the raw input from the user

    // Format the numeric value to include thousand separators and two decimals
    const formatPrice = (value) => {
        if (!value) return ""; // Handle empty input gracefully
        const numericValue = parseFloat(value.replace(/,/g, "")); // Remove commas for parsing
        if (isNaN(numericValue)) return value; // Return the raw value if it's not a valid number
        return numericValue.toLocaleString("en-US", {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        });
    };

    // Handle changes in input
    const handleChange = (e) => {
        const inputValue = e.target.value.replace(/[^0-9.]/g, ""); // Remove invalid characters
        setRawValue(inputValue); // Store raw value without formatting for correct parsing
    };

    // Apply formatting when user leaves the field (onBlur)
    const handleBlur = () => {
        const formattedValue = formatPrice(rawValue);
        setRawValue(formattedValue); // Apply formatted value
    };

    return (
        <TextField
            label="Price"
            variant="outlined"
            // value={rawValue} // Display the raw value while typing
            // onChange={handleChange} // Track user input in raw format
            // onBlur={handleBlur} // Format the value when the input loses focus
            {...register("price", {
                required: "Price is required",
                // onChange: (e) => handleChange(e), // Ensure this works with register
            })}
            error={!!errors.price}
            helperText={errors.price?.message}
            fullWidth
            // InputProps={{
            //     startAdornment: <InputAdornment position="start">$</InputAdornment>,
            // }}
            type='number'
        />
    );
};

export default PriceInput;
